import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import DashboardPage from "./components/DashboardPage";
import PrivateRoute from "./components/privateRoute";
import AdmPage from "./components/AdmPage";
import Header from "./components/Header"
import './styles/App.css'


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const storedLoggedIn = sessionStorage.getItem('loggedIn') === 'true';
    const storedUserRole = sessionStorage.getItem('userRole');
    if (storedLoggedIn) {
      setLoggedIn(true);
      setUserRole(storedUserRole);
    }
  }, []);

  

  const handleLogin = (usernameInput) => {
    setUsername(usernameInput);
    setLoggedIn(true);
    setUserRole(sessionStorage.getItem('userRole'));
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUsername('');
    setUserRole('');
    localStorage.removeItem('token'); 
    sessionStorage.removeItem('loggedIn');
    sessionStorage.removeItem('userRole');
    window.location.href = '/login';
  };



  return (
    <Router>
      <div className="app">
  
        
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={
              <Login
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                setUsername={setUsername}
                handleLogin={handleLogin}
              />
            }
          />
          <Route 
            path="/admin" 
            element={
              <PrivateRoute 
                loggedIn={loggedIn} 
                username={username} 
                userRole={userRole} 
                adminRoute={true} 
              />
            }
          >
          <Route 
            path="" 
            element={
              <>
                <Header 
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setUsername={setUsername}
                    username={username}
                    handleLogout={handleLogout}
                  />
                <AdmPage 
                  username={username} 
                  handleLogout={handleLogout} 
                  userRole={userRole} 
                />
              </>
            } 
          />
        </Route>
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute 
                loggedIn={loggedIn} 
                username={username} 
                userRole={userRole} 
              />
            }
          >
          <Route 
            path="" 
            element={
              <DashboardPage 
                username={username} 
                handleLogout={handleLogout} 
                userRole={userRole} 
              />
            } 
          />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
