/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import uploadIcon from "../imagens/icons/uploadIcon.png";
import trashIcon from "../imagens/icons/trashIcon.png";
import folderN from "../imagens/icons/folderN.png";
import folderIcon from "../imagens/icons/folder.png";
import backIcon from "../imagens/icons/back.png";

import locateIcon from "../imagens/icons/locateIcon.png";
import Footer from "./Footer"
import Header from "./Header"
//import { useNavigate } from 'react-router-dom';


//import handlePlayVideo from "./playVideo.js";
import "../styles/Fonts.css";
import "../styles/admin.css";




const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AdmPage = ({
    loggedIn,
    setLoggedIn,
    setUsername,
    username,
    handleLogout,
  }) => {


    // eslint-disable-next-line no-unused-vars
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [uniqueClients, setUniqueClients] = useState([]);
    const [stores, setStores] = useState([]);
    const [storeName, setStoreName] = useState(''); // Para armazenar o nome da loja atual
    const [storeFolders, setStoreFolders] = useState([]);
    const [screenName, setScreenName] = useState(''); // Para armazenar o nome da tela atual
    const [screenFolders, setScreenFolders] = useState([]);
    const [renderContent, setRenderContent] = useState('stores');
    const [videos, setVideos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [navigationHistory, setNavigationHistory] = useState([]);
    const [fileDate, setFileDate] = useState([]);



    //requisicoes de inicializacao
    
    const handleBackButtonClick = () => {
      window.location.reload();
    };


    useEffect(() => {
      const fetchFilesDate = async (screenName) => {
        let client = selectedClient
    
        if (client && storeName && screenName) {
          try {
            const response = await fetch(`${backendUrl}/fileDate?client=${client}/${storeName}/${screenName}`);
            if (response.ok) {
              const data = await response.json();
    
              setFileDate(data); // Atualiza o estado com os dados recebidos
            } else {
              console.error('Erro ao buscar arquivos:', response.statusText);
            }
          } catch (error) {
            console.error('Erro ao buscar arquivos:', error);
          }
        }
      };
    
      fetchFilesDate(screenName);
    }, [storeName, screenName, selectedClient]);







    const toggleVideoSelection = (video) => {
      const isSelected = selectedVideos.includes(video);
      if (isSelected) {
        setSelectedVideos(selectedVideos.filter((v) => v !== video));
      } else {
        setSelectedVideos([...selectedVideos, video]);
      }
    };




//solicitacao delete

const handleDeleteVideos = async (req, res) => {
  let client = selectedClient

  try {
    const url = `${backendUrl}/delete-video`; // Rota correta para exclusão de vídeo
    
    // Verificando se há vídeos selecionados
    if (selectedVideos.length === 0) {
      console.warn('Nenhum vídeo selecionado para exclusão.');
      return;
    }

    // Enviar uma solicitação DELETE para excluir os vídeos selecionados no servidor
    await Promise.all(selectedVideos.map(async (video) => {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          client:client, 
          directory: `${storeName}/${screenName}`,
          videoName: video // Renomear para videoName
        }),
      };
      
      const response = await fetch(`${url}?client=${client}/${storeName}/${screenName}&videoName=${video}`, requestOptions);
      console.log ('response', response)
      
      // Verificando se a exclusão foi bem-sucedida
      if (response.ok) {
       alert("Vídeo excluído com sucesso.");
      }else{
        throw new Error(`Falha ao excluir o vídeo ${video}.`);
      }
    }));

    // Atualizar a lista de vídeos após a exclusão
    const updatedVideos = videos.filter((video) => !selectedVideos.includes(video));
    setVideos(updatedVideos);
    
    // Limpar a seleção de vídeos após a exclusão
      setSelectedVideos([]);
    } catch (error) {
      console.error('Erro ao excluir vídeos:', error.message);
    }
};
  
const handleDeleteAllVideos = async (client, screenName) => {
   client = selectedClient

  try {
    // Obtém todos os inputs do tipo checkbox
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let checkedBox = false
    // Itera sobre os checkboxes para verificar quais estão marcados
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        screenName = checkbox.className;
        checkedBox = true
      }
    });
    if(!checkedBox){
      alert("Por favor, selecione pelo menos um vídeo para excluir.")
      return
    }
    
    // Envia a solicitação para a rota /uploadToAllStores
    const response = await fetch(`${backendUrl}/delete-all-video`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        username:client, 
        screenName:screenName
      }),
    });
      
    // Verifica se a solicitação foi bem-sucedida
    if (response.ok) {
      alert('Vídeos excluídos com sucesso.')
      handleBackButtonClick()
    } else {
      console.error('Falha ao excluir o vídeo:', response.statusText);
    }
    } catch (error) {
      console.error('Erro ao excluir vídeos:', error.message);
    }
  };



const adicionarVideo = (novoVideo) => {
  setVideos([...videos, novoVideo]);
};

const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]);
};


//solicitacao upload

const handleUpload = async (e, ) => {

  let client = selectedClient

  try {
    
    // Verificar se selectedFile está definido e é um objeto de arquivo válido
    if (!selectedFile || !(selectedFile instanceof File)) {
      console.error('ficheiro inválido:', selectedFile);
      alert("Por favor, selecioneum ficheiro de vídeo.")
      return;
    }

    // Verificar se o arquivo é um vídeo pela extensão
    const allowedExtensions = ['mp4', 'avi', 'mov', 'wmv']; // Adicione outras extensões de vídeo, se necessário
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      alert('Por favor, selecione um ficheiro de vídeo válido (MP4, AVI, MOV, WMV).');
      return;
    }

    const formData = new FormData();
    formData.append('video', selectedFile);
    formData.append('client', client); // Adicione username ao formData
    formData.append('storeName', storeName); // Adicione storeName ao formData
    formData.append('screenName', screenName); // Adicione screenName ao formData
    formData.entries().forEach(([key, value]) => {
    })
    if(renderContent !== 'screenFolders'){
      alert('Por favor, selecione uma pasta de ecrã.');
      return;
    }
    console.log('formData', formData)
   
    // Envia a solicitação para a rota /upload
    const response = await fetch(`${backendUrl}/upload`, {
     
      method: 'POST',
      body: formData
    });
    console.log('response', response)

    if (response.ok) {
      alert('Vídeo enviado com sucesso.');
    // Obter o nome do vídeo do FormData ou de outra fonte
    const nomeDoVideo = formData.get('video').name;
    // Adicionar o novo vídeo à lista de vídeos
    adicionarVideo(nomeDoVideo);
    } else {
      alert('Falha ao enviar o vídeo.');
    }
  } catch (error) {
    console.error('Erro ao enviar o vídeo:', error);
    alert('Erro ao enviar o vídeo. Por favor, tente novamente. Se o erro persistir, por favor, contacte o suporte.');
  }

};



const uploadToAllStores = async (client, screenName) => {
  client = selectedClient

  try {
    // Obtém todos os inputs do tipo checkbox
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let checkedBox = false
    // Itera sobre os checkboxes para verificar quais estão marcados
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        screenName = checkbox.className;
        checkedBox = true
      }
    });
    //manda uma mensagem de erro se nenhum checkbox foi marcado
    if(!checkedBox){
      alert('Por favor, selecione uma ecrã para enviar o vídeo.')
      return
    }
    // Verificar se selectedFile está definido e é um objeto de arquivo válido
    if (!selectedFile || !(selectedFile instanceof File)) {
      alert('Por favor, selecione um ficheiro de vídeo.');
      console.error('ficheiro inválido:', selectedFile);
      return;
    }
    // Verificar se o arquivo é um vídeo pela extensão
    const allowedExtensions = ['mp4', 'avi', 'mov', 'wmv']; // Adicione outras extensões de vídeo, se necessário
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      alert('Por favor, selecione um ficheiro de vídeo válido (MP4, AVI, MOV, WMV).');
      return;
    }
    // Cria um FormData para enviar os dados do vídeo
    const formData = new FormData();
    formData.append('video', selectedFile);
    formData.append('client', client); // Adicione username ao formData
    formData.append('screenName', screenName); // Adicione screenName ao formData

    

    // Envia a solicitação para a rota /uploadToAllStores
    const response = await fetch(`${backendUrl}/uploadToAllStores`, {
      method: 'POST',
      body: formData
    });
    
    // Verifica se a solicitação foi bem-sucedida
    if (response.ok) {
      alert('Vídeo enviado para todas as lojas com sucesso.');
      handleBackButtonClick();
    } else {
      console.error('Falha ao enviar o vídeo:', response.statusText);
    }
  } catch (error) {
    console.error('Erro ao enviar o vídeo:', error);
  }
};

//clientes 

useEffect(() => {
  const fetchClients = async () => {
    try {
      const response = await fetch( `${backendUrl}/clientList`);
      const data = await response.json();

      // Remover duplicidades
      const uniqueClientNames = Array.from(new Set(data.map(client => client.client)));

      // Atualizar o estado
      setClients(data);
      setUniqueClients(uniqueClientNames);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  };

  fetchClients();
}, []);

const ListBox = ({ clients, onChange }) => {
  return (
    <select onChange={onChange} defaultValue="">
      <option value="" disabled>Selecione um cliente</option>
      {clients.map((client, index) => (
        <option key={index} value={client}>
          {client}
        </option>
      ))}
    </select>
  );
};

const handleClientChange = (event) => {
  setSelectedClient(event.target.value);
};

let clientLists = (
  <div>
  <ListBox clients={uniqueClients} onChange={handleClientChange} />
  <p>Cliente selecionado: {selectedClient}</p>
  <p>Status de serviço: {selectedClient}</p>
</div>
)
//renderização de pastas do cliente

useEffect(() => {

  const fetchStores = async () => {
    const client = selectedClient;
    console.log(client);
    try {
      const response = await fetch(
        `${backendUrl}/folder?client=${client}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar lojas.");
      }
      const data = await response.json();
      setStores(data.folders); // Define as lojas no estado
    } catch (error) {
      throw new Error("Erro ao buscar lojas. Tente novamente mais tarde."); // Define o erro no estado
    }
  }
  fetchStores()

}, [selectedClient]);



  // Função para buscar as pastas dentro da loja atual
  const fetchStoreFolders = async (storeName) => {
    const client = selectedClient;
    try {
      const response = await fetch(
        `${backendUrl}/folder?client=${client}/${storeName}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar pastas da loja.");
      }
      const data = await response.json();

      setStoreFolders(data.folders); // Atualiza as pastas da loja no estado
      setStoreName(storeName); // Atualiza o nome da loja selecionada no estado
      //setCurrentPath(`/${teste2}/${storeName}`); // Atualiza o caminho atual
      setRenderContent('storeFolders');
    } catch (error) {
      console.error("Erro ao buscar pastas da loja:", error.message);
    
    }
    setNavigationHistory([...navigationHistory, 'stores']);
  };
  

  // Manipulador de evento para clicar em uma loja
  const handleStoreClick = (storeName) => {
    fetchStoreFolders(storeName);
  };

const fetchScreenFolders = async (screenName) => {
  const client = selectedClient;
  
  try {
    const response = await fetch(
      `${backendUrl}/folder?client=${client}/${storeName}/${screenName}`
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar pastas e vídeos da ecrã.");
    }
    const data = await response.json();


    // Verifica se existem vídeos na resposta
    if (data && data.videos) {
      // Atualiza o estado dos vídeos
      setVideos(data.videos);
    } else {
      console.warn("Resposta da API não contém vídeos.");
    }

    // Atualiza o estado das pastas
    setScreenFolders(data.folders);

    // Atualiza o nome da tela selecionada e o caminho atual
    setScreenName(screenName);
    //setCurrentPath(`/${teste2}/${storeName}/${screenName}`);
    setRenderContent('screenFolders');
  } catch (error) {
    console.error("Erro ao buscar pastas e vídeos da ecrã:", error.message);
   
  }
  setNavigationHistory([...navigationHistory, 'stores']);
};





const handleScreenClick = (screenName) => {
  fetchScreenFolders(screenName);
};



//conteudo de pastas

let content = null; 
// Renderização do conteúdo da loja atual, incluindo pastas dentro da loja
if (renderContent === 'stores') {
  // Se o conteúdo a ser renderizado são as lojas
  content = (
    <ul>
      {stores.map((store) => (
        <li key={store} onClick={() => handleStoreClick(store)}>
          <img className="folderN" src={locateIcon} style={{ cursor: 'pointer' }} alt="Ícone de pasta" />
          {store}
        </li>
      ))}
    </ul>
  );
} else if (renderContent === 'storeFolders') {
  // Se o conteúdo a ser renderizado são as pastas da loja
  content = (
    <div>
      <ul>
        {storeFolders.map((folder) => (
          <li key={folder} onClick={() => handleScreenClick(folder)}>
            {/* Adicionar imagem condicional aqui */}
            {folder === 'implantes' || folder === 'aparelhos' || folder === 'acessorios' ? (
              <img 
                className="folderN" 
                src={folderN}  // Caminho da primeira imagem específica
                style={{ cursor: 'pointer' }} 
                alt="Ícone de pasta" />
            ) : folder === 'montra' || folder === 'corner' || folder === 'ledwall' || folder ==='sala' ? (
              <img 
                className="folderN" 
                src={folderN}  // Caminho da segunda imagem específica
                style={{ cursor: 'pointer' }} 
                alt="Ícone de pasta" />
            ) : (
              <img 
                className="folderN" 
                src={folderN}  // Caminho da imagem genérica
                style={{ cursor: 'pointer' }}  
                alt="Ícone de pasta" />
            )}
            {folder}
          </li>
        ))}
      </ul>
    </div>
  );
} else if (renderContent === 'screenFolders') {
  // Se o conteúdo a ser renderizado são as pastas de tela
  content = (
    <div>
      <ul>
        {/* Renderizar pastas de tela */}
        {screenFolders.map((folder) => (
          <li key={folder}>
            {folder}
          </li>
        ))}
        {/* Renderizar vídeos */}
        {videos.map((video) => (
          <li key={video}>
            {video}
            <input
              type="checkbox"
              checked={selectedVideos.includes(video)}
              onChange={() => toggleVideoSelection(video)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
} else {
  content = <div>Conteúdo não disponível</div>;
}

//


let histContent = (
  <div>
    <ul>  
      {Array.isArray(fileDate.videos) && fileDate.videos.map((video, index) => (
        <li key={index}>
          <p>
            Nome: {video.nome} <br />
            Data de Modificação: {video.dataModificacao}
            Usuario: {username}
          </p>  
        </li>
      ))}
    </ul>
  </div>
);

//cotrole de pastas
let generalScreenWidex = (
  <div className="generalScreenContainer">
    <div className="generalScreenBox">
    <ul className="generalScreen">
      <li>
        <img className="folderN" src={folderN} alt="Ícone de acessorios " />
         Acessorios <span className="orientacaoTela">| Vertical</span>
         <input
         className="acessorios"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={folderN} alt="Ícone de aparelhos" />
         Aparelhos <span className="orientacaoTela">| Horizontal</span>
         <input
         className="aparelhos"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={folderN} alt="Ícone de implantes" />
         Implantes <span className="orientacaoTela">| Horizontal</span>
         <input
         className="implantes"
          type="checkbox"
          />
      </li>
    </ul>
    <ul className="generalScreen">
      <li>
        <img className="folderN" src={folderN} alt="Ícone de corner" />
         Corner <span className="orientacaoTela">| Horizontal</span>
         <input
         className="corner"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={folderN} alt="Ícone de montra" />
         Montra <span className="orientacaoTela">| Vertical</span>
         <input
         className="montra"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={folderN} alt="Ícone de ledwall" />
         Ledwall <span className="orientacaoTela">| Ledwall</span>
         <input
         className="ledwall"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={folderN} alt="Ícone de sala" />
         Sala <span className="orientacaoTela">| Horizontal</span>
         <input
         className="Sala"
          type="checkbox"
          />
      </li>
    </ul>
    </div>
  </div>
)
let generalScreenPlm = (
  <div className="generalScreenContainer">
    <div className="generalScreenBox">
      <ul className="generalScreen">
        <li>
          <img className="folderN" src={folderN} alt="Ícone de acessorios " />
          Tablets <span className="orientacaoTela">| Vertical/Horizontal</span>
          <input
          className="tablet"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={folderN} alt="Ícone de acessorios " />
          Expositores <span className="orientacaoTela">| Horizontal</span>
          <input
          className="expositor"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={folderN} alt="Ícone de acessorios " />
          Mupis <span className="orientacaoTela">| Vertical</span>
          <input
          className="muppie"
            type="checkbox"
            />
        </li>
      </ul>
      <ul className="generalScreen">
        <li>
          <img className="folderN" src={folderN} alt="Ícone de acessorios " />
          Televisores <span className="orientacaoTela">| Horizontal</span>
          <input
          className="TV"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={folderN} alt="Ícone de acessorios " />
          Ledwall <span className="orientacaoTela">| Horizontal</span>
          <input
          className="acessorios"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={folderN} alt="Ícone de acessorios " />
          Ecrã Transparente <span className="orientacaoTela">| Vertical</span>
          <input
          className="ecra transparente"
            type="checkbox"
            />
        </li>
      </ul>
    </div>
  </div>
)
let generalScreenHp = (
  <p>HP</p>
)

let clientScreen = selectedClient
const generalscreen = {
  widex : generalScreenWidex,
  plmretail : generalScreenPlm,
  hp : generalScreenHp

}


//renderiacao da pagina principal
    return(
        

        <div className="admPageWrapper"> 
          
          <div className="containerDashboard">
          <div className="lojaHistoryContainer">
            <div className="lojaContainer">
              <div className="generalBar" style={{ backgroundColor: "#818285" }}>
                <p className="txtGeneralBar">Clientes DCM</p>
                <div className="iconsGeneral">

                </div>
              </div>
              <div className="infoGeneralContainerL">
                <div>{clientLists}</div> 
              </div>
          </div>
          <div className="historyContainer">
            <div className="generalBar" style={{ backgroundColor: "#818285", height: '40px' } }>
              <p className="txtGeneralBar">Localizações</p>
              <div className="iconsGeneral">

                <img className="backIcon" style={{ cursor: 'pointer' }}  onClick={handleBackButtonClick} src={backIcon} alt="editIcon" />
                <label htmlFor="videoFile">
                  <img className="searchFle" style={{ cursor: 'pointer' }}  src={folderIcon} alt="uploadIcon" title="Escolher ficheiro"/>
                </label>
                <input type="file" id="videoFile" style={{ display: 'none' }} onChange={handleFileChange} />

                <img className="uploadIcon" onClick={handleUpload} style={{ cursor: 'pointer' }}  src={uploadIcon} alt="uploadIcon" title="Enviar vídeo"/>
                <img className="trashIcon" onClick={handleDeleteVideos} style={{ cursor: 'pointer' }}  src={trashIcon} alt="trashIcon" title="Excluir vídeo selecionado" />

              </div>
            </div>
            <div className="infoGeneralContainerL">
              <div>{content}</div>
            </div>
          </div>
          </div>
          <div className="lojaHistoryContainer">
            <div className="lojaContainer">
              <div className="generalBar" style={{ backgroundColor: "#818285" }}>
                <p className="txtGeneralBar">Tipologia de Ecrã</p>
                <div className="iconsGeneral">

                  <label htmlFor="videoFile">
                    <img className="searchFle" style={{ cursor: 'pointer' }}  src={folderIcon} alt="uploadIcon" title="Escolher ficheiro" />
                  </label>
                  <input type="file" id="videoFile" style={{ display: 'none' }} onChange={handleFileChange} />

                  <img className="uploadIcon" onClick={uploadToAllStores} style={{ cursor: 'pointer' }}  src={uploadIcon} alt="uploadIcon" title="Enviar vídeo para todas as pastas" />
                  <img className="trashIcon" onClick={handleDeleteAllVideos} style={{ cursor: 'pointer' }}  src={trashIcon} alt="trashIcon"title="Excluir vídeo de todas as pastas" />
             
                </div>
              </div>
              <div className="infoGeneralContainerL">
                <div>{generalscreen[clientScreen]}</div> 
              </div>
          </div>
          <div className="historyContainer">
            <div className="generalBar" style={{ backgroundColor: "#818285", height: '40px' } }>
              <p className="txtGeneralBar">Histórico</p>
              <div className="iconsGeneral">
              </div>
            </div>
            <div className="infoGeneralContainerL">
              <div>{histContent}</div>
            </div>
          </div>
        </div>
      </div>




      <footer>
        <Footer />
      </footer>
      </div>
    );
  };


  


  
  export default AdmPage;