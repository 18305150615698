import React, { useState, useEffect } from "react";
//import { useNavigate } from 'react-router-dom';
import uploadIcon from "../imagens/icons/uploadIcon.png";
import trashIcon from "../imagens/icons/trashIcon.png";
import userIcon from "../imagens/icons/userIcon.png";
import widexLogo from "../imagens/logos/widex.png";
//import widexLogo576 from "../imagens/logos/widex576.png"
//import dcmLogo from "../imagens/logos/dcmLogo.png";
import logoutIcon from "../imagens/icons/exitIcon.png";
import hpLogo from "../imagens/logos/hpLogo.png";
import plmLogo from "../imagens/logos/plmLogo.png";
//import folderN from "../imagens/icons/folderN.png";
import folderIcon from "../imagens/icons/folder.png";
import backIcon from "../imagens/icons/back.png";
import passwordIcon from "../imagens/icons/passwordIcon.png";
import reladIcon from "../imagens/icons/reloadIcon.png";
import configIcon from "../imagens/icons/configIcon.png";
import settingsWIcon from "../imagens/icons/settingsWIcon.png";
import earIcon from "../imagens/icons/earIcon.png";
import locateIcon from "../imagens/icons/locateIcon.png";
import storeIcon from "../imagens/icons/storeIcon.png";
import Footer from "./Footer.js"


//import handlePlayVideo from "./playVideo.js";
import "../styles/DashboardPage.css";
import "../styles/Fonts.css";
import "../styles/Header.css";


const backendUrl = process.env.REACT_APP_BACKEND_URL;


const DashboardPage = ({
  loggedIn,
  setLoggedIn,
  setUsername,
  username,
  handleLogout,
}) => { 
  const [backgroundColor, setBackgroundColor] = useState("");
  const [headerBgColor, setHeaderBgColor] = useState("");
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [logo, setLogo] = useState("");
  const [greeting, setGreeting] = useState("");
  const [userOffice, setUserOffice] = useState("");
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //const [currentPath, setCurrentPath] = useState(''); // Para controlar o caminho atual (usuário, loja, tela)
  const [storeName, setStoreName] = useState(''); // Para armazenar o nome da loja atual
  const [storeFolders, setStoreFolders] = useState([]);
  const [screenName, setScreenName] = useState(''); // Para armazenar o nome da tela atual
  const [screenFolders, setScreenFolders] = useState([]);
  const [renderContent, setRenderContent] = useState('stores');
  const [testeUserName, setTesteUserName] = useState(username);
  const [selectedFile, setSelectedFile] = useState(null);
  const [navigationHistory, setNavigationHistory] = useState([]);
  const [fileDate, setFileDate] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false); 
  const [changePassword, setChangePassword] = useState(false); 
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [bgSetting, setBgSetting] = useState('');
  const [loadScreen, setLoadScreen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); 
  const [confirDelete, setSetConfirDelete] = useState(false);
  //const navigate = useNavigate(); // Inicialize o useNavigate

  

  //--------------------------------------


  //--------------------------------------
  //armazenar nome de usuario


    // No logout, remova o nome de usuário do localStorage
    const logout = () => {
      handleLogout(); // Redireciona para a tela de login
      localStorage.removeItem('username');
      sessionStorage.clear();
    };

  //------------------------------

  let inactivityTimer; // Variável para armazenar o temporizador de inatividade

  // Função para redefinir o temporizador de inatividade
  function resetInactivityTimer() {
    clearTimeout(inactivityTimer); // Limpa o temporizador existente, se houver
    inactivityTimer = setTimeout(handleInactivity, 5 * 60 * 1000); // Define um novo temporizador de inatividade para 5 segundos
  }
  
  // Função para lidar com a inatividade do usuário
  function handleInactivity() {
    //handleLogout(); 
  }
  
  // Adiciona listeners de eventos para rastrear interações do usuário
  document.addEventListener('click', resetInactivityTimer);
  document.addEventListener('mousemove', resetInactivityTimer);
  document.addEventListener('keypress', resetInactivityTimer);
  
  // Inicializa o temporizador de inatividade
  resetInactivityTimer();

  //------------------------------

  
  // Add event listener for keydown when component mounts
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setChangePassword(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); 


  useEffect(() => {
    setTesteUserName(localStorage.getItem('username'))
    let client = sessionStorage.getItem('client')

    const fetchStores = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/folder?client=${client}`
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar lojas.");
        }
        const data = await response.json();
        setStores(data.folders); // Define as lojas no estado
        setLoading(false);
      } catch (error) {
        setError("Erro ao buscar lojas. Tente novamente mais tarde."); // Define o erro no estado
        setLoading(false);
      }
    }
  
    fetchStores();
  
  }, [username])


  useEffect(() => {

    let client = sessionStorage.getItem('client')

  const getHeaderBgColor = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/bgCollorHeader/${client}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      if (data.length === 0 || !data[0].bgCollorHeader) {
        console.error("Cor de fundo não encontrada ou indefinida.");
        return;
      }
      setHeaderBgColor(data[0].bgCollorHeader);
    } catch (error) {
      console.error("Erro ao buscar a cor de fundo:", error.message);
    }
  };  
    getHeaderBgColor();
  })

  useEffect(() => {

    let client = sessionStorage.getItem('client')
    
    const getImageLogo = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/userImageLogo/${client}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        setLogo(data[0].imageLogo);
      } catch (error) {
        console.error("Erro ao buscar a imagem:", error.message);
      }
    };
    getImageLogo()

     // Função para atualizar a largura da tela e verificar a mudança no tamanho da janela
     const handleResize = () => {
      setScreenWidth(window.innerWidth);
      console.log("Largura da tela:", screenWidth);
      console.log ("o logo é", logo)
    };

    // Adiciona um event listener para monitorar o redimensionamento da tela
    window.addEventListener("resize", handleResize);

    // Remove o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  },)

  useEffect(() => {
    let client = sessionStorage.getItem('client');

    const getbgSetting = async () => {
      try {
        const response = await fetch(`${backendUrl}/settingIcon/${client}`, {
          method: 'GET',
        });

        if (response.status === 503) {
          // Exibir mensagem de manutenção
          document.getElementById('message').innerText = 'Site em manutenção. Por favor, tente novamente mais tarde.';
          setError('Site em manutenção. Por favor, tente novamente mais tarde.');
          return; // Retorna para não tentar processar a resposta
        }

        if (!response.ok) {
          throw new Error(`Erro ao buscar as configurações: ${response.statusText}`);
        }

        const data = await response.json();
        setBgSetting(data[0].bgSettings);
      } catch (error) {
        console.error('Erro ao buscar as configurações:', error);
        setError('Erro ao buscar as configurações. Tente novamente mais tarde.');
      }
    };

    getbgSetting();
  }, []); 

  const settingMAp = {
    settingsWIcon : settingsWIcon,
    configIcon: configIcon
  }


  const logoMap = {
    widexLogo: widexLogo,
    plmLogoblack: plmLogo,
    hp:hpLogo
  };




  useEffect(() => {
    let client = sessionStorage.getItem('client')
    let username = localStorage.getItem('username')
    const getOffice = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/userOffice/${client}/${username}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        setUserOffice(data[0].office);
        
      } catch (error) {
        console.error("Erro ao buscar o cargo:", error.message);
      }
    };
    getOffice();
  },[username])


  useEffect(() => {
    let client = sessionStorage.getItem('client')
    let username = localStorage.getItem('username')

    
    const getClientName = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/clientName/${client}/${username}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        setGreeting(`Olá, ${data[0].clientName}`);
        
      } catch (error) {
        console.error("Erro ao buscar o cargo:", error.message);
      }
    };
    getClientName();

    
  },[username])




  useEffect(() => {

  let client = sessionStorage.getItem('client')

    const getBgcolorBar = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/bgCollor/${client}`,
          {
            method: "GET",
          }
        );

        const data = await response.json();	
        setBackgroundColor(data[0].bgCollor);
      } catch (error) {
        console.error("Erro ao buscar a cor de fundo:", error.message);
      }
    };
    getBgcolorBar();
  })

  



//rota get para historico
useEffect(() => {
  const fetchFilesDate = async (screenName) => {
    let client = sessionStorage.getItem('client')

    if (client && storeName && screenName) {
      try {
        const response = await fetch(`${backendUrl}/fileDate?client=${client}/${storeName}/${screenName}`);
        if (response.ok) {
          const data = await response.json();

          setFileDate(data); // Atualiza o estado com os dados recebidos
        } else {
          console.error('Erro ao buscar arquivos:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao buscar arquivos:', error);
      }
    }
  };

  fetchFilesDate(screenName);
}, [storeName, screenName]);
  


  

  useEffect(() => {

    let teste2 = localStorage.getItem('username')
    if(teste2 === null){
      teste2 = username
    }
    const fetchData = async () => {
      
      try {
        if (loggedIn) {
          //loginSuccess(teste2)

        } else {
          setBackgroundColor("");
          setHeaderBgColor("");
        }
      } catch (error) {
        console.error("Erro ao buscar informações:", error.message);
      }
    };


    fetchData();
  





  }, [loggedIn, setLoggedIn, setUsername, username, testeUserName]);


  window.addEventListener('load', () => {
    const username = localStorage.getItem('username');
    if (username) {
    }
});


  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


   // Estado para armazenar as pastas dentro da loja atual
  

  // Função para buscar as pastas dentro da loja atual
  const fetchStoreFolders = async (storeName) => {
    let client = sessionStorage.getItem('client')
    try {
      const response = await fetch(
        `${backendUrl}/folder?client=${client}/${storeName}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar pastas da loja.");
      }
      const data = await response.json();

      setStoreFolders(data.folders); // Atualiza as pastas da loja no estado
      setStoreName(storeName); // Atualiza o nome da loja selecionada no estado
      //setCurrentPath(`/${teste2}/${storeName}`); // Atualiza o caminho atual
      setRenderContent('storeFolders');
    } catch (error) {
      console.error("Erro ao buscar pastas da loja:", error.message);
    
    }
    setNavigationHistory([...navigationHistory, 'stores']);
  };
  

  // Manipulador de evento para clicar em uma loja

  const handleStoreClick = (storeName) => {

    fetchStoreFolders(storeName);
  };

  const fetchScreenFolders = async (screenName) => {
    let client = sessionStorage.getItem('client')
    
    try {
      const response = await fetch(
        `${backendUrl}/folder?client=${client}/${storeName}/${screenName}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar pastas e vídeos da ecrã.");
      }
      const data = await response.json();
  
  
      // Verifica se existem vídeos na resposta
      if (data && data.videos) {
        // Atualiza o estado dos vídeos
        setVideos(data.videos);
      } else {
        console.warn("Resposta da API não contém vídeos.");
      }
  
      // Atualiza o estado das pastas
      setScreenFolders(data.folders);
  
      // Atualiza o nome da tela selecionada e o caminho atual
      setScreenName(screenName);
      //setCurrentPath(`/${teste2}/${storeName}/${screenName}`);
      setRenderContent('screenFolders');
    } catch (error) {
      console.error("Erro ao buscar pastas e vídeos da ecrã:", error.message);
     
    }
    setNavigationHistory([...navigationHistory, 'stores']);
  };
  

  
  
  
  const handleScreenClick = (screenName) => {
    fetchScreenFolders(screenName);
  };

  const handleBackButtonClick = () => {
    window.location.reload();
  };



    const toggleVideoSelection = (video) => {
      const isSelected = selectedVideos.includes(video);
      if (isSelected) {
        setSelectedVideos(selectedVideos.filter((v) => v !== video));
      } else {
        setSelectedVideos([...selectedVideos, video]);
      }
    };

    const handleDeleteVideos = async (req, res) => {
      let client = sessionStorage.getItem('client')

      try {
        const url = `${backendUrl}/delete-video`; // Rota correta para exclusão de vídeo
        
        // Verificando se há vídeos selecionados
        if (selectedVideos.length === 0) {
          console.warn('Nenhum vídeo selecionado para exclusão.');
          return;
        }
    
        // Enviar uma solicitação DELETE para excluir os vídeos selecionados no servidor
        await Promise.all(selectedVideos.map(async (video) => {
          const requestOptions = {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              client:client, 
              directory: `${storeName}/${screenName}`,
              videoName: video // Renomear para videoName
            }),
          };
          
          const response = await fetch(`${url}?client=${client}/${storeName}/${screenName}&videoName=${video}`, requestOptions);
          console.log ('response', response)
          
          // Verificando se a exclusão foi bem-sucedida
          if (response.ok) {
           alert("Vídeo excluído com sucesso.");
          }else{
            throw new Error(`Falha ao excluir o vídeo ${video}.`);
          }
        }));
    
        // Atualizar a lista de vídeos após a exclusão
        const updatedVideos = videos.filter((video) => !selectedVideos.includes(video));
        setVideos(updatedVideos);
        
        // Limpar a seleção de vídeos após a exclusão
          setSelectedVideos([]);
        } catch (error) {
          console.error('Erro ao excluir vídeos:', error.message);
        }
    };
      
    const confirmDeletScreen = () => {
      setSetConfirDelete(prevState => !prevState);
      
    };

    const handleDeleteAllVideos = async (client, screenName) => {
      client = sessionStorage.getItem('client')
      confirmDeletScreen();
      try {
        // Obtém todos os inputs do tipo checkbox
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let checkedBox = false
        // Itera sobre os checkboxes para verificar quais estão marcados
        checkboxes.forEach(checkbox => {
          if (checkbox.checked) {
            screenName = checkbox.className;
            checkedBox = true
          }
        });
        if(!checkedBox){
          alert("Por favor, selecione pelo menos um vídeo para excluir.")
          return
        }
        
        // Envia a solicitação para a rota /uploadToAllStores
        const response = await fetch(`${backendUrl}/delete-all-video`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            client:client, 
            screenName:screenName
          }),
        });
          
        // Verifica se a solicitação foi bem-sucedida
        if (response.ok) {
          alert('Vídeos excluídos com sucesso.')
          handleBackButtonClick()
        } else {
          console.error('Falha ao excluir o vídeo:', response.statusText);
        }
        } catch (error) {
          console.error('Erro ao excluir vídeos:', error.message);
        }
      };



    const adicionarVideo = (novoVideo) => {
      setVideos([...videos, novoVideo]);
    };

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    

    const handleUpload = async (e, ) => {

      let client = sessionStorage.getItem('client')

      try {
        setLoadScreen(true)
        
        // Verificar se selectedFile está definido e é um objeto de arquivo válido
        if (!selectedFile || !(selectedFile instanceof File)) {
          console.error('ficheiro inválido:', selectedFile);
          alert("Por favor, selecioneum ficheiro de vídeo.")
          setLoadScreen(false)
          return;
        }

        // Verificar se o arquivo é um vídeo pela extensão
        const allowedExtensions = ['mp4', 'avi', 'mov', 'wmv']; // Adicione outras extensões de vídeo, se necessário
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          alert('Por favor, selecione um ficheiro de vídeo válido (MP4, AVI, MOV, WMV).');
          setLoadScreen(false)
          return;
        }

        const formData = new FormData();
        formData.append('video', selectedFile);
        formData.append('client', client); // Adicione username ao formData
        formData.append('storeName', storeName); // Adicione storeName ao formData
        formData.append('screenName', screenName); // Adicione screenName ao formData
        formData.entries().forEach(([key, value]) => {
        })
        if(renderContent !== 'screenFolders'){
          alert('Por favor, selecione uma pasta de ecrã.');
          setLoadScreen(false)
          return;
        }
        console.log('formData', formData)
      
        // Envia a solicitação para a rota /upload
        const response = await fetch(`${backendUrl}/upload`, {
        
          method: 'POST',
          body: formData
        });
        console.log('response', response)
    
        if (response.ok) {
          alert('Vídeo enviado com sucesso.');
        // Obter o nome do vídeo do FormData ou de outra fonte
        const nomeDoVideo = formData.get('video').name;
        // Adicionar o novo vídeo à lista de vídeos
        adicionarVideo(nomeDoVideo);
        } else {
          alert('Falha ao enviar o vídeo.');
        }
      } catch (error) {
        console.error('Erro ao enviar o vídeo:', error);
        alert('Erro ao enviar o vídeo. Por favor, tente novamente. Se o erro persistir, por favor, contacte o suporte.');
      } finally {
        setLoadScreen(false)
      }

    };


    
    const uploadToAllStores = async (client, screenName) => {
      client = sessionStorage.getItem('client')
    
      try {
        setLoadScreen(true)
        // Obtém todos os inputs do tipo checkbox
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let checkedBox = false
        // Itera sobre os checkboxes para verificar quais estão marcados
        checkboxes.forEach(checkbox => {
          if (checkbox.checked) {
            screenName = checkbox.className;
            checkedBox = true
          }
        });
        //manda uma mensagem de erro se nenhum checkbox foi marcado
        if(!checkedBox){
          alert('Por favor, selecione uma ecrã para enviar o vídeo.')
          setLoadScreen(false)
          return
        }
        // Verificar se selectedFile está definido e é um objeto de arquivo válido
        if (!selectedFile || !(selectedFile instanceof File)) {
          alert('Por favor, selecione um ficheiro de vídeo.');
          console.error('ficheiro inválido:', selectedFile);
          setLoadScreen(false)
          return;
        }
        // Verificar se o arquivo é um vídeo pela extensão
        const allowedExtensions = ['mp4', 'avi', 'mov', 'wmv']; // Adicione outras extensões de vídeo, se necessário
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          alert('Por favor, selecione um ficheiro de vídeo válido (MP4, AVI, MOV, WMV).');
          setLoadScreen(false)
          return;
        }
        // Cria um FormData para enviar os dados do vídeo
        const formData = new FormData();
        formData.append('video', selectedFile);
        formData.append('client', client); // Adicione username ao formData
        formData.append('screenName', screenName); // Adicione screenName ao formData
    
        
    
        // Envia a solicitação para a rota /uploadToAllStores
        const response = await fetch(`${backendUrl}/uploadToAllStores`, {
          method: 'POST',
          body: formData
        });
        
        // Verifica se a solicitação foi bem-sucedida
        if (response.ok) {
          alert('Vídeo enviado para todas as lojas com sucesso.');
          handleBackButtonClick();
        } else {
          console.error('Falha ao enviar o vídeo:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao enviar o vídeo:', error);
      } finally {
        setLoadScreen(false)
      }
    };
    
    
    
    const handleChangePassword = async (e) => {
      e.preventDefault();
  
      // Verificar se as senhas coincidem
      if (newPassword !== confirmPassword) {
        setErrorMessage('As senhas não coincidem');
        return;
      }
  
      try {
        // Enviar solicitação para alterar a senha
        const response = await fetch(`${backendUrl}/change-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            oldPassword,
            newPassword,
            confirmPassword,
          }),
        });
  
        const data = await response.json();
  
        // Verificar se a solicitação foi bem-sucedida
        if (data.success) {
          // Redirecionar para a página de login
          alert('Senha alterada com sucesso.');
          window.location.href = '';
        } else {
          setErrorMessage(data.message);
        }
      } catch (error) {
        console.error('Erro ao alterar a senha:', error);
        setErrorMessage('Ocorreu um erro ao tentar alterar a senha');
      }
    };


    

    const toggleMenu = () => {
      setMenuOpen(prevState => !prevState);
    };
    


    // eslint-disable-next-line no-unused-vars
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setChangePassword();
        toggleMenu();
      }
    };

      const toggleChangePassword = (e) => {
        toggleMenu();
        setChangePassword(prevState => !prevState);
      }

      let changePasswordRender = (
        <div className='changePassword' >
          {changePassword && (
          
            <div className="changePasswordContainer" style={{backgroundColor: backgroundColor}}>
               <form onSubmit={handleChangePassword}>
                <input
                className="userInput"
                  type="password"
                  placeholder="Senha Atual"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                /><br />
                <input
                className="userInput"
                  type="password"
                  placeholder="Nova Senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                /><br />
                <input
                className="userInput"
                  type="password"
                  placeholder="Confirmar Nova Senha"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                /><br />
                <button className="passwordButton"  type="submit">Alterar Senha</button>
              </form>
              {errorMessage && <p>{errorMessage}</p>}
            </div>   
          )}
        </div>
      );
    
      let togglemenuRender = (
        <div className={menuOpen ? 'menuRender menu-open' : 'menuRender menu-closed'}>
          {menuOpen && ( // Renderiza o menu apenas se menuOpen for true
            <ul className="menu">
              <li>
                <img className="menuImg" onClick={handleBackButtonClick} src={reladIcon} alt="Logo" />
                <p onClick={handleBackButtonClick} >Atualizar</p>
              </li>
              <li>
                <img className="menuImg" src={passwordIcon} alt="Logo" />
                <p onClick={toggleChangePassword}>Alterar Password</p>
              </li>
              <li>
                <img className="menuImg"  onClick={logout} src={logoutIcon} alt="Logo" />
                <p onClick={logout} >Terminar Sessão</p>
              </li>
            </ul>
          )}
        </div>
      );

      const toggleLoadScreen = (
        <div className={loadScreen  ? 'loadingRender loading-open' : 'loadingRender loading-closed'}>
          {loadScreen && (
            <div className="loadingRender loading-open">
              <div className="loader">
              </div>
            </div>
          )}
        </div>
      );



    


   
// ============================= Renderização =======================================
    
    let content = null; 
  // Renderização do conteúdo da loja atual, incluindo pastas dentro da loja
  if (renderContent === 'stores') {
    // Se o conteúdo a ser renderizado são as lojas
    content = (
      <ul>
        {stores.map((store) => (
          <li key={store} onClick={() => handleStoreClick(store)}>
            <img className="folderN" src={locateIcon} style={{ cursor: 'pointer' }} alt="Ícone de pasta" />
            {store}
          </li>
        ))}
      </ul>
    );
  } else if (renderContent === 'storeFolders') {
    // Se o conteúdo a ser renderizado são as pastas da loja
    content = (
      <div>
        <ul>
          {storeFolders.map((folder) => (
            <li key={folder} onClick={() => handleScreenClick(folder)}>
              {/* Adicionar imagem condicional aqui */}
              {folder === 'implantes' || folder === 'aparelhos' || folder === 'acessorios' ? (
                <img 
                  className="folderN" 
                  src={earIcon}  // Caminho da primeira imagem específica
                  style={{ cursor: 'pointer' }} 
                  alt="Ícone de pasta" />
              ) : folder === 'montra' || folder === 'corner' || folder === 'ledwall' || folder ==='sala' ? (
                <img 
                  className="folderN" 
                  src={storeIcon}  // Caminho da segunda imagem específica
                  style={{ cursor: 'pointer' }} 
                  alt="Ícone de pasta" />
              ) : (
                <img 
                  className="folderN" 
                  src={storeIcon}  // Caminho da imagem genérica
                  style={{ cursor: 'pointer' }} 
                  alt="Ícone de pasta" />
              )}
              {folder}
            </li>
          ))}
        </ul>
      </div>
    );
  } else if (renderContent === 'screenFolders') {
    // Se o conteúdo a ser renderizado são as pastas de tela
    content = (
      <div>
        <ul>
          {/* Renderizar pastas de tela */}
          {screenFolders.map((folder) => (
            <li key={folder}>
              {folder}
            </li>
          ))}
          {/* Renderizar vídeos */}
          {videos.map((video) => (
            <li key={video}>
              {video}
              <input
                type="checkbox"
                checked={selectedVideos.includes(video)}
                onChange={() => toggleVideoSelection(video)}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    content = <div>Conteúdo não disponível</div>;
  }
    


 // Verificar a inicialização de fileDate

// Renderização do conteúdo do histórico, incluindo pastas dentro do histórico
let histContent = (
  <div>
    <ul>  
      {Array.isArray(fileDate.videos) && fileDate.videos.map((video, index) => (
        <li key={index}>
          <p>
            Nome: {video.nome} <br />
            Data de Modificação: {video.dataModificacao}
            Usuario: {username}
          </p>  
        </li>
      ))}
    </ul>
  </div>
);

//cotrole de pastas
let generalScreenWidex = (
  <div className="generalScreenContainer">
    <div className="generalScreenBox">
    <ul className="generalScreen">
      <li>
        <img className="folderN" src={earIcon} alt="Ícone de acessorios " />
         Acessorios <span className="orientacaoTela">| Vertical</span>
         <input
         className="acessorios"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={earIcon} alt="Ícone de aparelhos" />
         Aparelhos <span className="orientacaoTela">| Horizontal</span>
         <input
         className="aparelhos"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={earIcon} alt="Ícone de implantes" />
         Implantes <span className="orientacaoTela">| Horizontal</span>
         <input
         className="implantes"
          type="checkbox"
          />
      </li>
    </ul>
    <ul className="generalScreen">
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de corner" />
         Corner <span className="orientacaoTela">| Horizontal</span>
         <input
         className="corner"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de montra" />
         Montra <span className="orientacaoTela">| Vertical</span>
         <input
         className="montra"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de ledwall" />
         Ledwall <span className="orientacaoTela">| Ledwall</span>
         <input
         className="ledwall"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de sala" />
         Sala <span className="orientacaoTela">| Horizontal</span>
         <input
         className="Sala"
          type="checkbox"
          />
      </li>
    </ul>
    </div>
  </div>
)
let generalScreenPlm = (
  <div className="generalScreenContainer">
    <div className="generalScreenBox">
      <ul className="generalScreen">
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Tablets <span className="orientacaoTela">| Vertical/Horizontal</span>
          <input
          className="tablet"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Expositores <span className="orientacaoTela">| Horizontal</span>
          <input
          className="expositor"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Mupis <span className="orientacaoTela">| Vertical</span>
          <input
          className="muppie"
            type="checkbox"
            />
        </li>
      </ul>
      <ul className="generalScreen">
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Televisores <span className="orientacaoTela">| Horizontal</span>
          <input
          className="TV"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Ledwall <span className="orientacaoTela">| Horizontal</span>
          <input
          className="acessorios"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Ecrã Transparente <span className="orientacaoTela">| Horizontal</span>
          <input
          className="ecra transparente"
            type="checkbox"
            />
        </li>
      </ul>
    </div>
  </div>
)
let generalScreenHp = (
  <p>HP</p>
)

let clientScreen = sessionStorage.getItem('client')
const generalscreen = {
  widex : generalScreenWidex,
  plmretail : generalScreenPlm,
  hp : generalScreenHp

}



let confirmDelet = (
  <div className={confirDelete  ? 'loadingRender loading-open' : 'loadingRender loading-closed'}>
    {confirDelete &&(
      <div className="confirmDeletContent">
        <p>Ao continuar, concorda em eliminar todo o conteúdo associado ao tipo de ecrã selecionado em todas as lojas.</p>
        <p>Tem a certeza de que deseja proceder?</p>

        <div className="confirmDeletBtns" >
          <button className="cancelDeletBtn" onClick={confirmDeletScreen}>Cancelar</button>
          <button className="confirmDeletBtn" onClick={handleDeleteAllVideos} >Proceder</button>
        </div>
      </div>
      )}
  </div>
);




    return (
      <div className="dashboardWrapper">
        <header className="header" style={{ backgroundColor: headerBgColor }}>
        <div className="logoUsercontainer">
            <img className="clientLogo"  src={logoMap[logo]} alt="Logo" />
            <div className="userOffice">
              <img className="userIcon"style={{ backgroundColor, borderRadius: '50%' }} src={userIcon} alt="Search" />
              <div className="textUserOffice">
                <h1 className="helloUser">{greeting}</h1>
                <p className="userOffice">{userOffice}</p>
              </div>
            </div>
        </div>
          <div className="imgLogoutPassword">
          <img
            className="logoutIcon"
            style={{ backgroundColor, borderRadius: '50%' }}
            onClick={() => {
              toggleMenu()
            }}
            src={settingMAp[bgSetting]}
            alt="Search"
          />
          {togglemenuRender}
          {changePassword && changePasswordRender}
          </div>
        </header>
        <div className="containerDashboard">
          <div className="generalContainerDashboard">
            <div className="generalBar" style={{ backgroundColor }}>
              <p className="txtGeneralBar">Localização {storeName ? ` - ${storeName}` : ''} {screenName ? ` - ${screenName}` : ''}</p>
              <div className="iconsGeneral">

              {storeName && (
                <>
                  <img className="backIcon" style={{ cursor: 'pointer' }} onClick={handleBackButtonClick} src={backIcon} alt="backIcon" />
                  
                  <label htmlFor="videoFile">
                    <img className="searchFle" style={{ cursor: 'pointer' }} src={folderIcon} alt="uploadIcon" title="Escolher ficheiro" />
                  </label>
                  <input type="file" id="videoFile" style={{ display: 'none' }} onChange={handleFileChange} />

                  <img className="uploadIcon" onClick={handleUpload} style={{ cursor: 'pointer' }} src={uploadIcon} alt="uploadIcon" title="Enviar vídeo" />
                  <img className="trashIcon" onClick={handleDeleteVideos} style={{ cursor: 'pointer' }} src={trashIcon} alt="trashIcon" title="Excluir vídeo selecionado" />
                  {toggleLoadScreen}
                </>
             )}
              </div>
            </div>
            <div className="infoGeneralContainer">
              <div>{content}</div>
            </div>
          </div>
          <div className="lojaHistoryContainer">
            <div className="lojaContainer">
              <div className="generalBar" style={{ backgroundColor }}>
                <p className="txtGeneralBar">Tipologia de Ecrã</p>
                <div className="iconsGeneral">

                  <label htmlFor="videoFile">
                    <img className="searchFle" style={{ cursor: 'pointer' }}  src={folderIcon} alt="uploadIcon" title="Escolher ficheiro" />
                  </label>
                  <input type="file" id="videoFile" style={{ display: 'none' }} onChange={handleFileChange} />

                  <img className="uploadIcon" onClick={uploadToAllStores} style={{ cursor: 'pointer' }}  src={uploadIcon} alt="uploadIcon" title="Enviar vídeo para todas as pastas" />
                  <img className="trashIcon" onClick={confirmDeletScreen} style={{ cursor: 'pointer' }}  src={trashIcon} alt="trashIcon"title="Excluir vídeo de todas as pastas" />
                  {confirmDelet}
                  {/*{toggleLoadScreen}*/}
                </div>
              </div>
              <div className="infoGeneralContainerL">
                <div>{generalscreen[clientScreen]}</div> 
              </div>
          </div>
          <div className="historyContainer">
            <div className="generalBar" style={{ backgroundColor, height: '40px' } }>
              <p className="txtGeneralBar">Histórico</p>
              <div className="iconsGeneral">
              </div>
            </div>
            <div className="infoGeneralContainerL">
              <div>{histContent}</div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default DashboardPage;
