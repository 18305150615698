import React, { useState, useEffect } from "react";
import dcmLogoAdmin from "../imagens/logos/dcmLogo.png";
import passwordIcon from "../imagens/icons/passwordIcon.png";
import reladIcon from "../imagens/icons/reloadIcon.png";
import configIcon from "../imagens/icons/configIcon.png";
import userIcon from "../imagens/icons/userIcon.png";
//import dcmLogo from "../imagens/logos/dcmLogo.png";
import logoutIcon from "../imagens/icons/exitIcon.png";


import "../styles/Header.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Header = ({
    loggedIn,
    setLoggedIn,
    setUsername,
    username,
    handleLogout,
  }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleBackButtonClick = () => {
        window.location.reload();
      };
      
    const logout = () => {
        console.log(username)
        handleLogout(); // Redireciona para a tela de login
        localStorage.removeItem('username');
        sessionStorage.clear();
      };

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
      };
      

      const handleChangePassword = async (e) => {
        e.preventDefault();
    
        // Verificar se as senhas coincidem
        if (newPassword !== confirmPassword) {
          //setErrorMessage('As senhas não coincidem');
          return;
        }
    
        try {
          // Enviar solicitação para alterar a senha
          const response = await fetch(`${backendUrl}/change-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username,
              oldPassword,
              newPassword,
              confirmPassword,
            }),
          });
    
          const data = await response.json();
    
          // Verificar se a solicitação foi bem-sucedida
          if (data.success) {
            // Redirecionar para a página de login
            alert('Senha alterada com sucesso.');
            window.location.href = '';
          } else {
            //setErrorMessage(data.message);
          }
        } catch (error) {
          console.error('Erro ao alterar a senha:', error);
          //setErrorMessage('Ocorreu um erro ao tentar alterar a senha');
        }
      };
  
      const toggleChangePassword = (e) => {
        toggleMenu();
        setChangePassword(prevState => !prevState);
      }


      useEffect(() => {
        const handleKeyDown = (e) => {
          if (e.key === 'Escape') {
            setChangePassword(false);
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener when component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []); 

      let changePasswordRender = (
        <div className='changePassword' >
          {changePassword && (
          
            <div className="changePasswordContainer" style={{backgroundColor: "#818285"}}>
               <form onSubmit={handleChangePassword}>
                <input
                className="userInput"
                  type="password"
                  placeholder="Senha Atual"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                /><br />
                <input
                className="userInput"
                  type="password"
                  placeholder="Nova Senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                /><br />
                <input
                className="userInput"
                  type="password"
                  placeholder="Confirmar Nova Senha"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                /><br />
                <button className="passwordButton"  type="submit">Alterar Senha</button>
              </form>
              
            </div>   
          )}
        </div>
      );
    
      let togglemenuRender = (
        <div className={menuOpen ? 'menuRender menu-open' : 'menuRender menu-closed'}>
          {menuOpen && ( // Renderiza o menu apenas se menuOpen for true
            <ul className="menu">
              <li>
                <img className="menuImg" onClick={handleBackButtonClick} src={reladIcon} alt="Logo" />
                <p onClick={handleBackButtonClick} >Atualizar</p>
              </li>
              <li>
                <img className="menuImg" src={passwordIcon} alt="Logo" />
                <p onClick={toggleChangePassword}>Alterar Password</p>
              </li>
              <li>
                <img className="menuImg" onClick={logout} src={logoutIcon} alt="Logo" />
                <p onClick={logout} >Terminar Sessão</p>
              </li>
            </ul>
          )}
        </div>
      );


    return ( 
        <header className="header" style={{ backgroundColor: "black" }}>
            <div className="logoUsercontainer">
                <img className="dcmLogoAdmin" src={dcmLogoAdmin} alt="Logo" />
                <div className="userOffice">
                  <img className="userIcon" style={{backgroundColor: "#818285", borderRadius: '50%'}} src={userIcon} alt="Search" />
                  <div className="textUserOffice">
                    <h1 className="helloUser">Ola, Tiago Costa</h1>
                    <p className="userOffice">Dev PLM</p>
                  </div>
                </div>
            </div>
            <div className="imgLogoutPassword">
            <img
              className="logoutIcon"
              onClick={() => {
                toggleMenu()
              }}
              src={configIcon}
              alt="Search"
            />
            {togglemenuRender}
            {changePassword && changePasswordRender}
            </div>
          </header>
     );
}

export default Header;