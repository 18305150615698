import React from "react";
import sloganImg from '../imagens/logos/slogan.png';
import plmLogoBlack from '../imagens/logos/plmLogoblack.png';
import '../styles/Footer.css'

const Footer = () => {
  return(
    <div className='footerContent'>
    <img className='sloganImg' src={sloganImg} alt='Logo' />
    <img className='plmLogoBlack' src={plmLogoBlack} alt='Logo' />
  </div>
  )
}

export default Footer;